import { AspectRatio } from "./aspect-ratio";
import { Check } from "lucide-react";
import { url } from "../../../src/config";
import { useState, useEffect } from "react";

type Props = {
  _id: { $oid: string };
  oid?: boolean;
  selected?: { _id: string }[] | { _id: string };
  showSelected?: boolean;
  w?: string;
  orientation?: string;
};

const Image = ({
  _id,
  oid,
  selected,
  showSelected,
  w,
  orientation,
  ...rest
}: Props) => {
  const isSelected =
    selected &&
    (_isArray(selected)
      ? selected.some((item) => item._id === _id.$oid)
      : selected._id === _id.$oid);

  // const [refreshKey, setRefreshKey] = useState(0);
  const [_oid, setOid] = useState<any>(oid ? _id.$oid : _id);

  //fech image and img
  // useEffect(() => {
  //   setRefreshKey(Date.now());
  // }, []);

  return (
    <div
      className={`block rounded-sm p-[10px] relative max-w-[200px] w-full`}
      {...rest}
      key={orientation}
    >
      {isSelected && showSelected && (
        <div className="absolute top-0 right-0 z-[5] flex bg-black text-white rounded-full p-[4px] ">
          <Check size={"1em"} />
        </div>
      )}

      <AspectRatio ratio={1 / 1}>
        <img
          key={Date.now()}
          src={`${url}/media/serveInFly/${_oid}?w=300&h=300&ext=webp&q=90`}
          className={`w-full h-full object-cover rounded-sm max-w-${w}`}
        />
      </AspectRatio>
    </div>
  );
};

function _isArray(value: any): value is any[] {
  return Array.isArray(value);
}

export { Image };
export default Image;
