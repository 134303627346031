import { IMenu } from "./types";
const TaneMenu: IMenu[] = [
  {
    name: "Strony",
    icon: "Image",
    items: [
      {
        name: "lista",
        url: "/page/list",
        icon: "plus",
      },
      {
        name: "dodaj",
        url: "/page/add",
        icon: "plus",
      },
    ],
  },
  {
    name: "Schematy",
    icon: "home",
    items: [
      {
        name: "Lista",
        url: "/creator/list",
        icon: "plus",
      },
      {
        name: "Dodaj",
        url: "/creator/add",
        icon: "plus",
      },
    ],
  },
  {
    name: "Promocje/Kupony",
    icon: "Precent",
    items: [
      {
        name: "Promocje",
        url: "/masspromo",
        icon: "Precent",
      },
      {
        name: "Kupony",
        url: "/cupons",
        icon: "Precent",
      },
    ],
  },
  // {
  //   name: "Menu",
  //   icon: "Text",
  //   items: [
  //     {
  //       name: "Lista",
  //       url: "/menu/list",
  //       icon: "plus",
  //     },
  //     {
  //       name: "Dodaj",
  //       url: "/menu/add",
  //       icon: "plus",
  //     },
  //   ],
  // },
  {
    name: "Zamówienia",
    icon: "Text",
    url: "/order/list",
  },
  {
    name: "media",
    icon: "camera",
    url: "/media",
  },
  // {
  //   name: "Karenty",
  //   icon: "Folders",
  //   url: "/karnety/list",
  // },
  {
    name: "Konfiguracja",
    icon: "Cog",
    url: "/newConfig",
  },
  // {
  //   name: "Dokumenty",
  //   icon: "home",
  //   items: [
  //     {
  //       name: "Lista",
  //       url: "/document/list",
  //       icon: "plus",
  //     },
  //     {
  //       name: "Dodaj",
  //       url: "/document/add",
  //       icon: "plus",
  //     },
  //   ],
  // },
];

export default TaneMenu;
