import React from "react";
import Field from "./Field";
import { AddFieldDialogProps } from "../../types/types";
import Details from "./DialogDetails";
import { Button } from "./../button";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
  DialogHeader,
} from "./../dialog";

import {
  ArrowLeft,
  Youtube,
  UploadCloud,
  Edit2,
  Image,
  Check,
  CalendarDays,
  Upload,
  GalleryHorizontal,
  GalleryHorizontalEnd,
  TextSelect,
  ListTodo,
  ListChecks,
  WholeWord,
  SquareAsterisk,
  Square,
  RectangleHorizontal,
  Heading,
  Sliders,
  GalleryThumbnails,
  LayoutList,
} from "lucide-react";
import { ScrollArea } from "../scroll-area";

const AddFieldDialog = (props: AddFieldDialogProps) => {
  type TfieldType = {
    icon: any;
    text: string;
    label?: string;
    component: string;
    valueType: "single" | "multi" | "none" | "endpoint" | "fixeddirectory";
  };

  const fields: TfieldType[] = [
    {
      icon: WholeWord,
      text: "Małe pole tekstwe",
      component: "text",
      valueType: "single",
    },
    {
      icon: WholeWord,
      text: "Slug",
      component: "inputSlug",
      valueType: "single",
    },
    {
      icon: SquareAsterisk,
      text: "Hasło",
      component: "password",
      valueType: "single",
    },
    {
      icon: TextSelect,
      text: "Duże pole tekstowe",
      component: "textarea",
      valueType: "single",
    },
    {
      icon: Check,
      text: "Checkbox",
      component: "checkbox",
      valueType: "single",
    },
    {
      icon: LayoutList,
      text: "Lista",
      component: "select",
      valueType: "multi",
    },
    {
      icon: LayoutList,
      text: "Lista z endpoint",
      component: "selectEndpoint",
      valueType: "endpoint",
    },
    {
      icon: ListChecks,
      text: "Lista wielokrotna z endpoint",
      component: "multiselectEndpoint",
      valueType: "endpoint",
    },
    {
      icon: ListTodo,
      text: "Lista wielokrotna",
      component: "multiselect",
      valueType: "multi",
    },
    {
      icon: TextSelect,
      text: "Edytor",
      component: "editor",
      valueType: "none",
    },
    {
      icon: Image,
      text: "Obrazek",
      component: "image",
      label: "Obrazek",
      valueType: "none",
    },
    {
      icon: GalleryHorizontalEnd,
      text: "Galeria",
      component: "gallery",
      label: "Galeria",
      valueType: "fixeddirectory",
    },
    {
      icon: Upload,
      text: "Ładuj plik",
      component: "fileupload",
      label: "Ładowanie pliku",
      valueType: "none",
    },
    {
      icon: CalendarDays,
      text: "Data",
      component: "date",
      label: "Data",
      valueType: "none",
    },
    {
      text: "SlideShow",
      component: "slideshow",
      label: "Slideshow",
      icon: GalleryThumbnails,
      valueType: "none",
    },
    // {
    //     'text': 'Logo slider',
    //     'component': 'slider',
    //     'label': 'Logo slider',
    //     'icon': mdiSlide,
    //     'valueType': 'none'
    // },
    {
      text: "Fragment",
      component: "fragment",
      label: "Fragment",
      icon: Square,
      valueType: "none",
    },
    {
      icon: Heading,
      text: "Label",
      component: "dynamicLabel",
      label: "Label",
      valueType: "none",
    },
    {
      icon: RectangleHorizontal,
      text: "Przycisk",
      component: "button",
      label: "Przycisk",
      valueType: "none",
    },
    {
      icon: Youtube,
      text: "Youtube",
      component: "ytLink",
      label: "Youtube",
      valueType: "none",
    },
    {
      icon: ListTodo,
      text: "Drzewo checkboxów",
      component: "treeCheckbox",
      valueType: "endpoint",
    },
  ];

  const [valueType, setValueType] = React.useState<
    "none" | "single" | "multi" | "endpoint" | "fixeddirectory"
  >("none");
  const [fieldType, setFieldType] = React.useState<any>("");
  const [defaultValues, setDefaultValues] = React.useState<any | undefined>();
  const [step, SetStep] = React.useState<"step1" | "step2">("step1");

  const showDetails = (el: TfieldType) => {
    setValueType(el!.valueType);
    setFieldType(el!.component);
    SetStep("step2");
  };

  React.useEffect(() => {
    SetStep("step1");
    if (props?.field?.input) {
      const f = fields.findIndex((el) => {
        return el.component == props.field.input;
      });
      if (f > -1) {
        setFieldType(fields[f].component);
        setDefaultValues(props?.field);
        setValueType(
          fields[f].valueType as "none" | "single" | "multi" | "endpoint"
        );
        SetStep("step2");
      }
    }
    return () => {
      SetStep("step1");
      setFieldType("");
      setDefaultValues(undefined);
      setValueType("none");
    };
  }, [props.open]);

  const Title = () => {
    if (step == "step1") {
      return <div>Wybierz typ pola</div>;
    } else {
      return (
        <div className="flex items-center">
          <Button
            variant="ghost"
            onClick={() => {
              SetStep("step1");
            }}
          >
            {" "}
            <ArrowLeft size={"15px"} />
          </Button>

          <div>
            <h5>Podaj szczegóły pola</h5>
          </div>
        </div>
      );
    }
  };

  return (
    <Dialog open={props.open} onOpenChange={props.onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <Title />
          </DialogTitle>
        </DialogHeader>
        <ScrollArea className="h-[75vh]  pr-4">
          {step == "step2" && (
            <Details
              onClose={props.onClose}
              fieldType={fieldType}
              defaultValues={defaultValues}
              valuesType={valueType}
              save={(vals) => {
                vals["component"] = fieldType;
                props.onSave(vals);
                props.onClose();
              }}
            />
          )}
          {step == "step1" && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "1em",
              }}
            >
              {fields.map((el, index) => {
                return (
                  <div key={index.toString()}>
                    <Field
                      icon={el.icon}
                      text={el.text}
                      onClick={() => {
                        showDetails(el);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </ScrollArea>
        {step == "step1" && (
          <DialogFooter>
            <Button
              onClick={() => {
                props.onClose();
              }}
            >
              Anuluj
            </Button>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default AddFieldDialog;
