import React, { Reducer } from "react";

import { fieldStateType, DialogDetailsProps } from "../../types/types";
import { cloneDeep } from "lodash";
import { Button } from "../button";
import { Minus, Plus } from "lucide-react";
import { InputWithLabel } from "../inputWithLabel";
import { Checkbox } from "../checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../select";
import { Label } from "../label";
import { ScrollArea } from "../scroll-area";
import { fi } from "date-fns/locale";
type multiValuesType = {
  value?: any;
  label?: any;
};
type valuesActionType = {
  type: "add" | "remove" | "set";
  payload?: {
    value: string | number | undefined;
    index: number;
    fieldType: "value" | "label";
  };
};

type stateType = {
  options?: multiValuesType[] | undefined;
};

type fieldsActionType = {
  type:
    | "name"
    | "label"
    | "style"
    | "defaultValue"
    | "required"
    | "validatationType"
    | "readonly"
    | "autoOperation"
    | "reqxe"
    | "defaultValueMulti"
    | "endpoint"
    | "clear"
    | "full"
    | "valueEndpoint"
    | "labelEndpoint"
    | "systemfunc"
    | "fixeddirectory";
  payload: any;
};

const Details = (props: DialogDetailsProps) => {
  let fieldsIntial: fieldStateType = {
    name: "",
    label: "",
    style: undefined,
    defaultValue: undefined,
    required: false,
    validatationType: "none",
    regexp: undefined,
    options: undefined,
    endpoint: undefined,
    valueEndpoint: undefined,
    labelEndpoint: undefined,
    readonly: false,
    autoOperation: undefined,
    systemfunc: undefined,
    fixeddirectory: undefined,
  };

  const valuesInital = {
    options: [
      {
        value: "",
        label: "",
      },
    ],
  };

  if (props?.defaultValues) {
    fieldsIntial.name = props?.defaultValues.name;
    fieldsIntial.label = props?.defaultValues.label;
    fieldsIntial.validatationType = props?.defaultValues.validator;
    fieldsIntial.style = props?.defaultValues.sx;
    fieldsIntial.regexp = props.defaultValues.regex;
    fieldsIntial.defaultValue = props.defaultValues.value;
    fieldsIntial.fixeddirectory = props.defaultValues.fixeddirectory;
    if (props.valuesType == "multi" && props.defaultValues.options) {
      valuesInital.options = props.defaultValues.options;
    }
    fieldsIntial.required = props.defaultValues.required;
    fieldsIntial.endpoint = props.defaultValues.endpoint;
    fieldsIntial.readonly = props.defaultValues.readonly;
    fieldsIntial.valueEndpoint = props.defaultValues.valueEndpoint;
    fieldsIntial.labelEndpoint = props.defaultValues.labelEndpoint;
    fieldsIntial.autoOperation = props.defaultValues.autoOperation;
    fieldsIntial.systemfunc = props.defaultValues.systemfunc;
  }

  const valuesAction = (
    state: stateType,
    action: valuesActionType
  ): stateType => {
    const { type, payload } = action;
    switch (type) {
      case "add":
        if (state.options) {
          const state_copy = [...state.options];
          state_copy.push({ value: "", label: "" });
          return { options: state_copy };
        } else {
          throw new Error("no state");
        }
      case "set":
        if (state.options && payload) {
          const state_copy = cloneDeep(state.options);
          state_copy[payload.index][payload.fieldType] = payload!.value;

          return { options: state_copy };
        } else {
          throw new Error("no state");
        }

      case "remove":
        if (payload && payload?.index >= 0 && state.options) {
          let state_copy = [...state.options];
          state_copy.splice(payload.index, 1);
          return { options: state_copy };
        } else {
          throw new Error("no state");
        }
      default:
        return state;
    }
  };

  const fieldAction = (
    fieldState: fieldStateType,
    action: fieldsActionType
  ): fieldStateType => {
    return { ...fieldState, [action.type]: action.payload };
  };

  const [fieldsState, fieldsDispatch] = React.useReducer<
    Reducer<fieldStateType, fieldsActionType>
  >(fieldAction, fieldsIntial);

  const [state, valuesDispatch] = React.useReducer<
    Reducer<stateType, valuesActionType>
  >(valuesAction, valuesInital);

  const regxepOn = React.useMemo(() => {
    return fieldsState.validatationType == "regex";
  }, [fieldsState.validatationType]);

  const save = () => {
    const dataToDispatch = { ...fieldsState };
    dataToDispatch.options = state.options;

    props.save(dataToDispatch);
  };

  return (
    <div>
      <ScrollArea className="h-[60vh]  pr-4">
        <div>
          <div
            style={{
              marginTop: "1em",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1em",
            }}
          >
            <InputWithLabel
              label={"nazwa"}
              defaultValue={fieldsState.name}
              className="w-full"
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                fieldsDispatch({ type: "name", payload: event.target.value });
              }}
            />

            <InputWithLabel
              label={"label"}
              defaultValue={fieldsState.label}
              className="w-full"
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                fieldsDispatch({ type: "label", payload: event.target.value });
              }}
            />
          </div>
          <div
            style={{
              marginTop: "1em",
              display: "grid",
              gridTemplateColumns: "1fr",
              gap: "1em",
            }}
          >
            <InputWithLabel
              label={"style"}
              defaultValue={fieldsState.style}
              className="w-full"
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                fieldsDispatch({ type: "style", payload: event.target.value });
              }}
            />
          </div>

          <>
            <div
              style={{
                marginTop: "1em",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "1em",
              }}
            >
              <InputWithLabel
                label={"wartość domyślna"}
                defaultValue={fieldsState.defaultValue}
                className="w-full"
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                  fieldsDispatch({
                    type: "defaultValue",
                    payload: event.target.value,
                  });
                }}
              />
            </div>
          </>

          <>
            {props?.fieldType == "text" && (
              <div
                style={{
                  marginTop: "1em",
                  marginBottom: "1em",
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gap: "1em",
                }}
              >
                <InputWithLabel
                  label={"Auto formuła"}
                  defaultValue={fieldsState.autoOperation}
                  className="w-full"
                  onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                    fieldsDispatch({
                      type: "autoOperation",
                      payload: event.target.value,
                    });
                  }}
                />
              </div>
            )}
          </>
          <div
            style={{
              marginTop: "1em",
              marginBottom: "1em",
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr",
              gap: "1em",
            }}
          >
            <InputWithLabel
              label={"Funkcja systemowa"}
              defaultValue={fieldsState.systemfunc}
              className="w-full"
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                fieldsDispatch({
                  type: "systemfunc",
                  payload: event.target.value,
                });
              }}
            />
          </div>

          {props.valuesType == "endpoint" && (
            <>
              <div
                style={{
                  marginTop: "1em",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1em",
                }}
              >
                <InputWithLabel
                  label={"endpoint"}
                  defaultValue={fieldsState.endpoint}
                  onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                    fieldsDispatch({
                      type: "endpoint",
                      payload: event.target.value,
                    });
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: "1em",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1em",
                }}
              >
                <InputWithLabel
                  label={"wartość endpoint"}
                  defaultValue={fieldsState.valueEndpoint}
                  onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                    fieldsDispatch({
                      type: "valueEndpoint",
                      payload: event.target.value,
                    });
                  }}
                />

                <InputWithLabel
                  label={"label endpoint"}
                  defaultValue={fieldsState.labelEndpoint}
                  onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                    fieldsDispatch({
                      type: "labelEndpoint",
                      payload: event.target.value,
                    });
                  }}
                />
              </div>
            </>
          )}

          {props.valuesType == "fixeddirectory" && (
            <div
              style={{
                marginTop: "1em",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "1em",
              }}
            >
              <InputWithLabel
                label={"Stały katolog"}
                defaultValue={fieldsState.fixeddirectory}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                  fieldsDispatch({
                    type: "fixeddirectory",
                    payload: event.target.value,
                  });
                }}
              />
            </div>
          )}

          {props.valuesType == "multi" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1em",
                }}
              >
                <strong>Dodaj nową wartość</strong>
                <Button
                  variant="ghost"
                  onClick={() => {
                    valuesDispatch({ type: "add" });
                  }}
                  color="secondary"
                >
                  <Plus size="15px" />
                </Button>
              </div>
              {state?.options?.map((el, idx) => {
                return (
                  <div className="flex gap-2 items-center">
                    <InputWithLabel
                      label={"wartość"}
                      defaultValue={el.value}
                      style={{ width: "100%" }}
                      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                        valuesDispatch({
                          type: "set",
                          payload: {
                            index: idx,
                            fieldType: "value",
                            value: event.target.value,
                          },
                        });
                      }}
                    />

                    <InputWithLabel
                      label={"Label"}
                      defaultValue={el.label}
                      className="w-full"
                      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                        valuesDispatch({
                          type: "set",
                          payload: {
                            index: idx,
                            fieldType: "label",
                            value: event.target.value,
                          },
                        });
                      }}
                    />
                    <Button
                      variant="ghost"
                      onClick={() => {
                        valuesDispatch({
                          type: "remove",
                          payload: {
                            index: idx,
                            fieldType: "label",
                            value: undefined,
                          },
                        });
                      }}
                    >
                      <Minus size={"15px"} />
                    </Button>
                  </div>
                );
              })}
            </>
          )}

          <div className="flex gap-5 items-center">
            <div className="flex gap-2 items-center">
              <Label>Wymagane</Label>
              <Checkbox
                defaultChecked={fieldsIntial.required}
                onCheckedChange={(value) => {
                  fieldsDispatch({ type: "required", payload: value });
                }}
              />
            </div>
            <div className="flex gap-2 items-center">
              <Label>Tylko do odczytu</Label>
              <Checkbox
                defaultChecked={fieldsIntial.readonly}
                onCheckedChange={(value) => {
                  fieldsDispatch({ type: "readonly", payload: value });
                }}
              />
            </div>
          </div>
          <div className="grid gap-2 items-center mt-5">
            <div className="flex gap-2 items-center">
              <Label>Walidacja</Label>

              <Select
                defaultValue={fieldsState.validatationType}
                onValueChange={(value) =>
                  fieldsDispatch({ type: "validatationType", payload: value })
                }
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Theme" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="none">Bez</SelectItem>
                  <SelectItem value="number">Liczba</SelectItem>
                  <SelectItem value="date">Data</SelectItem>
                  <SelectItem value="email">E-mail</SelectItem>
                  <SelectItem value="tekst">Tekst</SelectItem>
                  <SelectItem value="regex">Regxep</SelectItem>
                </SelectContent>
              </Select>
            </div>

            {regxepOn == true && (
              <InputWithLabel
                label={"regxep"}
                className="w-full"
                defaultValue={fieldsState.regexp}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                  fieldsDispatch({
                    type: "reqxe",
                    payload: event.target.value,
                  });
                }}
              />
            )}
          </div>
        </div>
      </ScrollArea>
      <div
        style={{
          marginTop: "1em",
          display: "flex",
          justifyContent: "end",
          gap: "1em",
        }}
      >
        <Button
          variant={"ghost"}
          onClick={() => {
            props.onClose();
          }}
        >
          Zamknij
        </Button>
        <Button
          variant="outline"
          onClick={() => {
            save();
          }}
        >
          Zapisz
        </Button>
      </div>
    </div>
  );
};
export default Details;
