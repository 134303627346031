import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { EnhancedFromProps } from "./types";

import { useWithValidation } from "../../hooks/useForm";
import { Plus, UnfoldVertical, FoldVertical } from "lucide-react";

import _, { cloneDeep, isEmpty } from "lodash";
import { Button } from "../ui/button";
import { Components } from "./Components";
import { RowOperations } from "./RowOperations";
import processSystemFunc from "../../lib/processSystemFunctions";
import { useParams } from "react-router-dom";
import { useToast } from "src/components/ui/use-toast";

import { Combobox } from "../ui/combobox";

type errorType = {
  text: string;
  num: number;
};

type errorStateType = {
  [key: string]: errorType;
};

export type ImperativeHandle = {
  saveEmit: () => void;
};

const EnhancedFrom = forwardRef<ImperativeHandle, EnhancedFromProps>(
  (props, refEmit) => {
    const [formData, setFormData] = React.useState<object>({});
    const [schema, setSchema] = React.useState<any[]>([]);
    const [errorState, setStateError] = React.useState<errorStateType[]>([]);
    const [hookAutoCalc, setHookAutoCalc] = React.useState<any[]>([]);
    const [multipleItems, setMultipleItems] = React.useState<any>({});
    const params = useParams();
    const formWithout = useWithValidation;
    const ref = new Set();
    const [refresh, setRefresh] = React.useState<number>(0);

    const functionRegister = {
      showEmailDialog: (item: any) => {
        props?.specialFunctions?.openMailDialog();
      },
      changeTurnus: (item: any) => {
        props?.specialFunctions?.openTurnusChangeDialog();
      },
      getGalleryLink: (item: any) => {
        //get url
        const par = params;
        const link = `https://client.koniar3.usermd.net/login?cid=${par["id"]}&login=${formData["header"][0]["login"]}`;
        navigator.clipboard.writeText(
          "Login: " +
            formData["header"][0]["password"] +
            "\nLink do panelu:" +
            link +
            "\n"
        );
        toast({
          variant: "success",
          title: "Skopiowano do schowka",
          description: "",
        });
      },
      console: (item: any) => {
        console.log("aaa");
      },
    };

    React.useEffect(() => {
      const sh = cloneDeep(props.schema);
      const sh2 = cloneDeep(props.schema);
      let index2 = 0;

      sh.forEach((el: any) => {
        const name = el.mainBlock.name;

        if (el.mainBlock.multiple && props?.values) {
          const count = props?.values?.[0]?.[name]?.length;
          index2 = sh2.findIndex((item: any) => item.mainBlock.name == name);

          for (let i = 0; i < count - 1; i++) {
            const mainblock_clone = cloneDeep(el);
            mainblock_clone.num = i + 1;
            mainblock_clone.mainBlock.multiple = false;

            sh2.splice(index2 + i + 1, 0, mainblock_clone);
          }
        }
      });

      //get all subBlocks names from sh block
      const subBlocksList: {
        name: string;
        index: number;
        blockIndex: number;
        elName: string;
        num?: number;
      }[] = [];
      sh2.forEach((el: any, i: number) => {
        el?.mainBlock?.blocks?.forEach((block: any, k: number) => {
          block?.subBlockName &&
            el.mainBlock.name &&
            subBlocksList.push({
              name: block.subBlockName,
              index: i,
              blockIndex: k,
              elName: el.mainBlock.name,
              num: el.num || 0,
            });
        });
      });

      const names_unique = Array.from(
        new Set(subBlocksList.map((el) => el.elName))
      );

      names_unique.forEach((name: string) => {
        for (let w = 0; w <= props?.values?.[0]?.[name]?.length - 1; w++) {
          if (props?.values?.[0]?.[name]?.[w]?.subBlocks) {
            Object.keys(props?.values?.[0]?.[name]?.[w]?.subBlocks).map(
              (elx) => {
                subBlocksList.map((el: any) => {
                  if (el.elName == name && el.num == w && el.name == elx) {
                    const mainBlock = cloneDeep(
                      sh2[el.index].mainBlock.blocks[el.blockIndex]
                    );
                    mainBlock.multi = false;
                    if (
                      props?.values?.[0]?.[name]?.[w]?.subBlocks[elx].length > 0
                    ) {
                      sh2[el.index].mainBlock.blocks[el.blockIndex].subBlocks =
                        [];
                    }

                    for (
                      let o = 0;
                      o <
                      props?.values?.[0]?.[name]?.[w]?.subBlocks[elx].length;
                      o++
                    ) {
                      sh2[el.index].mainBlock.blocks[
                        el.blockIndex
                      ].subBlocks.push(mainBlock);
                    }
                  }
                });
              }
            );
          }
        }
      });

      if (props.values) {
        if (props.values[0] != undefined) {
          setFormData(props.values[0]);
        } else {
          setFormData(props.values);
        }
        if (sh2.length > 0) {
          const _schema = cloneDeep(sh2);
          setSchema(_schema);
        }
      } else {
        setSchema(sh2);
      }

      // Get AutoCalc Hook
      const hooks: any[] = [];
      props.schema.forEach((block: any) => {
        block.mainBlock.blocks.forEach((blocks: any) => {
          blocks.block.items.forEach((item: any, indexItem: number) => {
            if (item?.autoOperation) {
              let baseFieldAbsolute = (field: any) => `parseFloat(${field})`;
              let basefield = (field: any) =>
                `parseFloat(form['${block.mainBlock.name}'][num]['${field}'])`;

              let operation = item?.autoOperation.replace("=", "");
              //regex for + - * / ( )
              let regex = /(\+|\-|\*|\/|\(|\))/g;
              let fields = operation.split(regex).map((el: any) => el.trim());
              let result = 0;
              let operationString = "";
              const trigger: string[] = [];

              fields.forEach((field: any) => {
                if (
                  field === "+" ||
                  field === "-" ||
                  field === "*" ||
                  field === "/" ||
                  field === "(" ||
                  field === ")"
                ) {
                  operationString += field;
                } else {
                  if (field != "") {
                    if (field.startsWith("!")) {
                      operationString += baseFieldAbsolute(
                        field.replace("!", "")
                      );
                    } else {
                      operationString += basefield(field);
                    }
                    trigger.push(field.replace("!", ""));
                  }
                }
              });

              const hook = {
                operation: operationString,
                segment: block.mainBlock.name,
                target: item.name,
                triggers: Array.from(new Set(trigger)),
              };
              hooks.push(hook);
            }
          });
        });
      });

      setHookAutoCalc(hooks);
    }, [props.schema, props.values, refresh]);

    const setItem = (
      name: string,
      value: any,
      nameBlock: string,
      num: number,
      isSubBlock: boolean,

      subBlockNum?: number,
      subBlockName?: string,
      subItemNum?: number
    ) => {
      let form = cloneDeep(formData);

      if (form == undefined) {
        form = {};
      }

      if (form) {
        if (isSubBlock == false) {
          if (nameBlock.length > 0) {
            if (!form[nameBlock]) {
              form[nameBlock] = [];
            }

            if (form[nameBlock] && form[nameBlock].length >= num + 1) {
            } else {
              if (Array.isArray(form[nameBlock])) {
                form[nameBlock].push({});
              }
            }
            if (Array.isArray(form[nameBlock])) {
              form[nameBlock][num] = { ...form[nameBlock][num], [name]: value };
            } else {
              form[nameBlock] = { ...form[nameBlock], [name]: value };
            }
          } else {
            form[name] = value;
          }
        }

        if (
          isSubBlock == true &&
          subBlockName !== undefined &&
          subBlockName != ""
        ) {
          if (nameBlock.length > 0) {
            if (form[nameBlock] && form[nameBlock].length >= num + 1) {
            } else {
              if (Array.isArray(form[nameBlock])) {
                // form[nameBlock][0].subBlocks.push({});
              }
            }
            if (Array.isArray(form[nameBlock])) {
              if (!form[nameBlock][num].subBlocks) {
                form[nameBlock][num].subBlocks = {};
              }
              if (!form[nameBlock][num].subBlocks[subBlockName]) {
                form[nameBlock][num].subBlocks[subBlockName] = [];
              }

              if (form[nameBlock][num].subBlocks[subBlockName][subBlockNum]) {
                form[nameBlock][num].subBlocks[subBlockName][subBlockNum] = {
                  ...form[nameBlock][num].subBlocks[subBlockName][subBlockNum],
                  [name]: value,
                };
              } else {
                form[nameBlock][num].subBlocks[subBlockName][subBlockNum] = {
                  [name]: value,
                };
              }
            } else {
              // form[nameBlock].subItems = {
              //   ...form[nameBlock].subItems,
              //   [name]: value,
              // };
            }
          } else {
            form[name] = value;
          }
        }

        /// AUTOCALC HOOK EXECUTE
        const hook = hookAutoCalc.filter((el) => {
          return el.segment == nameBlock && el.triggers.includes(name);
        });

        for (let i = 0; i < hook.length; i++) {
          let w = hook[i].operation.replace("num", num.toString());

          if (!form[hook[i].segment][num].hasOwnProperty(hook[i].target)) {
            form[hook[i].segment][num][hook[i].target] = 0;
          }

          form[hook[i].segment][num][hook[i].target] = eval(w) || "0";
        }

        const hookAbsolute = hookAutoCalc.filter((el) => {
          return el.triggers.includes(`form['${nameBlock}'][num]['${name}']`);
        });

        for (let i = 0; i < hookAbsolute.length; i++) {
          let w = hookAbsolute[i].operation.replace("num", num.toString());

          if (hookAbsolute[i].target.length > 0) {
            if (!form[hookAbsolute[i].segment]) {
              form[hookAbsolute[i].segment] = [];
            }

            if (
              form[hookAbsolute[i].segment] &&
              form[hookAbsolute[i].segment].length >= num + 1
            ) {
            } else {
              if (Array.isArray(form[hookAbsolute[i].segment])) {
                form[hookAbsolute[i].segment].push({});
              }
            }
            if (Array.isArray(form[hookAbsolute[i].segment])) {
              form[hookAbsolute[i].segment][num] = {
                ...form[hookAbsolute[i].segment][num],
                [hookAbsolute[i].target]: value,
              };
            } else {
              form[hookAbsolute[i].segment] = {
                ...form[hookAbsolute[i].segment],
                [hookAbsolute[i].target]: value,
              };
            }
          } else {
            form[hookAbsolute[i].target] = value;
          }

          if (
            !form[hookAbsolute[i].segment][num].hasOwnProperty(
              hookAbsolute[i].target
            )
          ) {
            form[hookAbsolute[i].segment][num][hookAbsolute[i].target] = 0;
          }

          form[hookAbsolute[i].segment][num][hookAbsolute[i].target] =
            eval(w) || "0";
        }

        setFormData({ ...form });
      }
    };

    const isAnyError = () => {
      return isEmpty(Object.keys(errorState));
    };

    const setError = (
      name: string,
      error: boolean,
      text: string,
      num: number | undefined
    ) => {
      const payload: { name: string; data: { text: string; num: number } } = {
        name: name,
        data: { text: text, num: num || 0 },
      };
      const key = `${payload.name}-${payload.data.num}` as string;

      if (error == true) {
        errorState[key] = payload.data;
        setStateError(errorState);
      } else {
        delete errorState[key];
        setStateError(errorState);
      }
    };

    const resetForm = () => {
      // Object.keys(formData).map(el => {
      //     formData[el as any] = undefined
      // })
      // setFormData({ ...formData });
    };

    const checkError = (name: string, num: number) => {
      if (errorState[`${name}-${num}`]) {
        return { isError: true, errorText: errorState[`${name}-${num}`].text };
      } else {
        return { isError: false, errorText: "" };
      }
    };

    const getValue = (
      name: string,
      itemName: string,
      num: number,
      isSubBlock: boolean,
      subBlockNum?: number,
      subBlockName?: string,
      numItem?: number,
      subItemNum?: number
    ) => {
      if (!isSubBlock) {
        if (formData?.[name] !== undefined) {
          const w = formData?.[name]?.[num]?.[itemName]
            ? formData?.[name]?.[num]?.[itemName]
            : formData?.[name]?.[itemName]
            ? formData?.[name]?.[itemName]
            : "";

          return w;
        } else {
          return formData?.[itemName] ? formData?.[itemName] : "";
        }
      } else {
        if (subBlockNum !== undefined && subBlockName !== undefined) {
          return (
            formData?.[name][num]?.subBlocks?.[subBlockName]?.[
              subBlockNum || 0
            ]?.[itemName] || ""
          );
        }

        if (formData?.[name] !== undefined) {
          return "";
          // return formData?.[name][num].subBlocks[subBlockName][
          //   subBlockNum || 0
          // ][itemName];
        } else {
          return "";
        }
      }
    };

    const moveDownSubField = (
      subIndex: number,
      index: number,
      index3: number,
      num: number = 0
    ) => {
      const _schema = cloneDeep(schema);
      const _form = cloneDeep(formData);

      const name = _schema[index].mainBlock.name;
      const subName = _schema[index]?.mainBlock.blocks[index3];

      const temp = _form[name][num].subBlocks[subName.subBlockName][subIndex];
      _form[name][num].subBlocks[subName.subBlockName][subIndex] =
        _form[name][num].subBlocks[subName.subBlockName][subIndex + 1];
      _form[name][num].subBlocks[subName.subBlockName][subIndex + 1] = temp;

      setFormData(_form);
      setSchema(_schema);
    };

    const moveUpSubField = (
      subIndex: number,
      index: number,
      index3: number,
      num: number = 0
    ) => {
      const _schema = cloneDeep(schema);
      const _form = cloneDeep(formData);

      const name = _schema[index].mainBlock.name;
      const subName = _schema[index]?.mainBlock.blocks[index3];

      const temp = _form[name][num].subBlocks[subName.subBlockName][subIndex];
      _form[name][num].subBlocks[subName.subBlockName][subIndex] =
        _form[name][num].subBlocks[subName.subBlockName][subIndex - 1];
      _form[name][num].subBlocks[subName.subBlockName][subIndex - 1] = temp;

      setFormData(_form);
      setSchema(_schema);
    };

    const deleteSubField = (
      subIndex: number,
      index: number,
      index3: number,
      num: number = 0
    ) => {
      const _schema = cloneDeep(schema);
      const _form = cloneDeep(formData);

      _schema[index].mainBlock.blocks[index3].subBlocks.splice(subIndex, 1);

      const name = _schema[index].mainBlock.name;
      const subName = _schema[index]?.mainBlock.blocks[index3];

      _form[name][num].subBlocks[subName.subBlockName].splice(subIndex, 1);

      setFormData(_form);
      setSchema(_schema);
    };

    const changeAction = (
      item: any,
      name: string,
      num: number,
      value: any,
      isSubBlock: boolean,
      subBlockNum?: number,
      subBlockName?: string,
      subItemNum?: number
    ) => {
      switch (props.validationType) {
        case "ACTIVE":
          formWithout(setError, value, item, name, num);
          break;
        default:
          setItem(
            item.name,
            value,
            name,
            num,
            isSubBlock,
            subBlockNum,
            subBlockName,
            subItemNum
          );
          break;
      }
    };

    const requiredValidation = () => {
      ref.forEach((el: any) => {
        const field = formData[el.name]?.[el.num]?.[el.item.name];

        if (
          el.item.required == true &&
          (field == undefined || field == 0 || field == "")
        ) {
          setError(el.item.name, true, "to pole jest wymagane", el.num);
        }
      });
    };

    useEffect(() => {
      console.log(errorState, "errorState");
    }, [...errorState]);

    const postValidation = () => {
      ref.forEach((el: any) => {
        if (formData[el.name]?.[el.num]?.[el.item.name]) {
          formWithout(
            setError,
            formData[el.name][el.num][el.item.name],
            el.item,
            el.name,
            el.num
          );
        } else {
          if (formData[el.name]) {
            Object.keys(formData[el.name]).map((elx: any) => {
              if (formData[el.name][elx] && elx == el.item.name) {
                formWithout(
                  setError,
                  formData[el.name][elx],
                  el.item,
                  elx,
                  el.num
                );
              }
            });
          }
        }
      });
    };
    const { toast } = useToast();
    const save = () => {
      setStateError([]);
      switch (props.validationType) {
        case "ACTIVE":
          requiredValidation();
          break;
        case "ON_SUBMIT":
          requiredValidation();
          postValidation();
          break;
        case "DISABLED":
          break;
      }
      const _schema = cloneDeep(schema);
      setSchema(_schema);
      const itPass = isAnyError();

      !itPass &&
        toast({
          variant: "destructive",
          title: "Oj! Coś poszło nie tak !",
          description: "Sprawdź formularz i spróbuj ponownie.",
        });

      if (props?.onSave && itPass) {
        props.onSave(formData);
      }
    };

    useImperativeHandle(refEmit, () => ({
      saveEmit() {
        setStateError([]);
        save();
      },
      resetEmit() {
        setStateError([]);
        resetForm();
      },
    }));

    const addField = (index: number) => {
      try {
        const maxnum = findMaxNum(schema[index].mainBlock.name);
        const _schema = cloneDeep(schema);
        const _form = cloneDeep(formData);
        const name = _schema[index].mainBlock.name;
        _form[name].push({});
        const mainBlock = cloneDeep(_schema[index]);

        mainBlock.mainBlock.blocks.forEach((el: any) => {
          el.subBlocks = [];
        });

        _schema.splice(index + maxnum + 1, 0, {
          ...mainBlock,
          num: maxnum + 1,
        });
        let renumerate = 1;
        _schema.forEach((el: any) => {
          if (el.mainBlock.name == name && el?.num) {
            el.num = renumerate;
            renumerate = renumerate + 1;
          }
        });

        setFormData(_form);
        setSchema(_schema);
      } catch (e) {
        toast({
          variant: "destructive",
          title: "Uwaga",
          description: "Zanim dodasz wiersz musisz uzupełnić pierwszy wiersz",
        });
      }
    };

    const deleteField = (index: number, fieldNum: number | undefined) => {
      const _schema = cloneDeep(schema);
      const _form = cloneDeep(formData);
      const name = _schema[index].mainBlock.name;

      _form[name].splice(fieldNum, 1);

      _schema.splice(index, 1);

      let renumerate = 1;
      _schema.forEach((el: any) => {
        if (el.mainBlock.name == name && el?.num) {
          el.num = renumerate;
          renumerate = renumerate + 1;
        }
      });

      setFormData(_form);
      setSchema(_schema);
    };

    const moveDown = (index: number, num: number) => {
      const name = schema[index].mainBlock.name;
      const _form: any = cloneDeep(formData);
      const _schema: any = cloneDeep(schema);
      const p1: any = _form[name][num];

      const p2: any = _form[name][num + 1];

      _form[name][num] = p2;
      _form[name][num + 1] = p1;
      let renumerate = 1;
      _schema.forEach((el: any) => {
        if (el.mainBlock.name == name && el?.num) {
          el.num = renumerate;
          renumerate = renumerate + 1;
        }
      });
      setFormData(_form);
    };

    const moveUp = (index: number, num: number) => {
      const name = schema[index].mainBlock.name;
      const _form: any = cloneDeep(formData);
      const p1 = _form[name][num];
      const p2 = _form[name][num - 1];
      _form[name][num] = p2;
      _form[name][num - 1] = p1;

      setFormData(_form);
    };

    const findMaxNum = (name: string) => {
      let max = 0;
      schema.forEach((el: any) => {
        if (el.mainBlock.name == name && el?.num) {
          max = el.num;
        }
      });
      return max;
    };

    type TFloadAble = {
      children: React.ReactNode;
      foldAble?: boolean;
      el?: any;
      index2: number;
    };

    const setMyFlod = (el) => {
      el.mainBlock.unFlod = !el.mainBlock.unFlod;

      setSchema([...schema]);
    };

    const renderBlock = (
      items: any,
      index2: number,
      name: string,
      num: number = 0,
      key?: string,
      isSubBlock: boolean = false,
      subBlockNum?: number,
      subBlockName?: string,
      numItem?: number,
      subItemNum?: number
    ) => {
      const odd = num % 2 == 0 ? "transparent" : "rgba(0,0,0,0.03)";

      return (
        <div className="flex items-end gap-4">
          <div
            className="w-full"
            style={{ ...items?.sx }}
            key={`w${num}-${index2}-${name}-${key}`}
          >
            {items?.items.map((item: any, index: number) => {
              ref.add({ name: name, num: num, item: item });

              const localFunctionsRegister = processSystemFunc(
                item?.systemfunc
              );

              const getter = (isSubBlock) => {
                if (Components[item.input]["action"] == "onCheckedChange") {
                  const fn = (value: any) => {
                    changeAction(
                      item,
                      name,
                      num,
                      value,
                      isSubBlock,
                      subBlockNum,
                      subBlockName,
                      subItemNum
                    );
                    localFunctionsRegister["click"].forEach((el: any) => {
                      functionRegister[el](item);
                    });
                  };

                  return { onCheckedChange: fn };
                }
                if (Components[item.input]["action"] == "onClick") {
                  const fn = (event: any) => {
                    changeAction(
                      item,
                      name,
                      num,
                      event.target.value,
                      isSubBlock,
                      subBlockNum,
                      subBlockName,
                      subItemNum
                    );
                    localFunctionsRegister["click"].forEach((el: any) => {
                      functionRegister[el](item);
                    });
                  };

                  return { onClick: fn };
                }
                if (Components[item.input]["action"] == "onChange") {
                  const fn = (event: any) => {
                    changeAction(
                      item,
                      name,
                      num,
                      event.target.value,
                      isSubBlock,
                      subBlockNum,
                      subBlockName,
                      subItemNum
                    );
                    localFunctionsRegister["change"].forEach((el: any) => {
                      functionRegister[el](item);
                    });
                  };
                  return { onChange: fn };
                }

                if (Components[item.input]["action"] == "onChangeRaw") {
                  const fn = (value: any) => {
                    changeAction(
                      item,
                      name,
                      num,
                      value,
                      isSubBlock,
                      subBlockNum,
                      subBlockName,
                      subItemNum
                    );

                    localFunctionsRegister["change"].forEach((el: any) => {
                      functionRegister[el](item);
                    });
                  };
                  return { onChange: fn };
                }
              };

              return (
                <div className="flex">
                  <div
                    key={`u${index}-${index2}-${name}-${num}-`}
                    className={`mt-4 w-full ${item?.sx && items.sx} `}
                  >
                    <>
                      {item?.input &&
                        React.createElement(
                          Components[item.input]["component"],
                          {
                            value: getValue(
                              name,
                              item.name,
                              num,
                              isSubBlock,
                              subBlockNum,
                              subBlockName,
                              subItemNum
                            ),
                            defaultChecked: getValue(
                              name,
                              item.name,
                              num,
                              isSubBlock,
                              subBlockNum,
                              subBlockName,
                              subItemNum
                            ),
                            label: item.label,
                            ...getter(isSubBlock),
                            options: item.options,
                            endpoint: item.endpoint,
                            labelendpoint: item?.labelEndpoint,
                            valueendpoint: item?.valueEndpoint,
                            readOnly: item?.readonly,
                            fixeddirectory: item?.fixeddirectory,
                            oid: params["id"],
                            sx: item?.sx,
                            key: `u${index}-${index2}-${name}-${num}-${key}`,
                          }
                        )}
                      {(errorState[`${item.name}-${num}-${key}`] ||
                        errorState[`${item.name}-${num}`] ||
                        errorState[`${item.name}-${num}-${key}-undefined`]) && (
                        <div className="text-red-500 text-xs">
                          {errorState[`${item.name}-${num}-${key}`]?.text}
                          {errorState[`${item.name}-${num}`]?.text}
                        </div>
                      )}
                    </>
                    <>
                      {item?.subItems &&
                        item?.subItems.map((elx: any, index8) => {
                          ref.add({ name: name, num: num, item: elx });
                          console.log(elx, "elx", "<xx");

                          const localFunctionsRegister = processSystemFunc(
                            item?.systemfunc
                          );

                          const getter = () => {
                            if (
                              Components[elx.input]["action"] ==
                              "onCheckedChange"
                            ) {
                              const fn = (value: any) => {
                                changeAction(
                                  elx,
                                  name,
                                  num,
                                  value,
                                  isSubBlock,
                                  subBlockNum,
                                  subBlockName
                                );
                                localFunctionsRegister["click"].forEach(
                                  (el: any) => {
                                    functionRegister[el](elx);
                                  }
                                );
                              };

                              return { onCheckedChange: fn };
                            }
                            if (Components[elx.input]["action"] == "onClick") {
                              const fn = (event: any) => {
                                changeAction(
                                  elx,
                                  name,
                                  num,
                                  event.target.value,
                                  isSubBlock,

                                  subBlockNum,
                                  subBlockName
                                );
                                localFunctionsRegister["click"].forEach(
                                  (el: any) => {
                                    functionRegister[el](elx);
                                  }
                                );
                              };

                              return { onClick: fn };
                            }
                            if (Components[elx.input]["action"] == "onChange") {
                              const fn = (event: any) => {
                                changeAction(
                                  elx,
                                  name,
                                  num,
                                  event.target.value,
                                  isSubBlock,

                                  subBlockNum,
                                  subBlockName
                                );
                                localFunctionsRegister["change"].forEach(
                                  (el: any) => {
                                    functionRegister[el](elx);
                                  }
                                );
                              };
                              return { onChange: fn };
                            }

                            if (
                              Components[elx.input]["action"] == "onChangeRaw"
                            ) {
                              const fn = (value: any) => {
                                changeAction(elx, name, num, value, false);

                                localFunctionsRegister["change"].forEach(
                                  (el: any) => {
                                    functionRegister[el](elx);
                                  }
                                );
                              };
                              return { onChange: fn };
                            }
                          };
                          return (
                            <>
                              {elx?.input &&
                                React.createElement(
                                  Components[elx.input]["component"],
                                  {
                                    value: getValue(
                                      name,
                                      elx.name,
                                      num,
                                      isSubBlock,
                                      subBlockNum,
                                      subBlockName,
                                      numItem,
                                      index8
                                    ),
                                    defaultChecked: getValue(
                                      name,
                                      elx.name,
                                      num,
                                      isSubBlock,
                                      subBlockNum,
                                      subBlockName,
                                      subItemNum
                                    ),
                                    label: elx.label,
                                    ...getter(),
                                    options: elx.options,
                                    endpoint: elx.endpoint,
                                    labelendpoint: elx?.labelEndpoint,
                                    valueendpoint: elx?.valueEndpoint,
                                    readOnly: elx?.readonly,
                                    fixeddirectory: elx?.fixeddirectory,
                                    oid: params["id"],
                                    sx: elx?.sx,
                                    key: `u${index}-${index2}-${name}-${num}-${index8}`,
                                  }
                                )}
                              {errorState[`${elx.name}-${num}`] && (
                                <div className="text-red-500 text-xs">
                                  {errorState[`${elx.name}-${num}`].text}
                                </div>
                              )}
                            </>
                          );
                        })}
                    </>
                  </div>
                </div>
              );
            })}
          </div>
          {items?.block && renderBlock(items.block, index2, name, num)}
        </div>
      );
    };

    //remove form multipleItems where multiple === true

    const addSubRow = (
      index: number,
      index2: number,
      name: string,
      num: number
    ) => {
      const _schema = cloneDeep(schema);
      const _form = cloneDeep(formData);

      const nameProd = _schema[index].mainBlock.name;

      let currentIndex;

      if (num == undefined || num == 0) {
        currentIndex = _schema.findIndex((el) => {
          return el.mainBlock.name == nameProd;
        });
      } else {
        currentIndex = _schema.findIndex((el) => {
          return el.mainBlock.name == nameProd && el.num == num;
        });
      }

      if (!_schema[currentIndex].mainBlock.blocks[index2]?.subBlocks) {
        _schema[currentIndex].mainBlock.blocks[index2].subBlocks = [];
      }
      const newOne = cloneDeep(_schema[currentIndex].mainBlock.blocks[index2]);

      delete newOne.subBlocks;
      newOne.num =
        _schema[currentIndex].mainBlock.blocks[index2].subBlocks.length;
      newOne.multi = false;
      _schema[currentIndex].mainBlock.blocks[index2].subBlocks.push(newOne);
      try {
        if (name !== "") {
          if (!_form[_schema[index]?.mainBlock?.name]?.[num]?.subBlocks) {
            _form[_schema[index].mainBlock.name][num].subBlocks = {};
          }

          if (!_form[_schema[index].mainBlock.name][num].subBlocks[name]) {
            _form[_schema[index].mainBlock.name][num].subBlocks[name] = [];
          }

          setFormData(_form);
          setSchema(_schema);
        }
      } catch (e) {
        toast({
          variant: "destructive",
          title: "Uwaga",
          description: "Zanim dodasz wiersz musisz uzupełnić pierwszy wiersz",
        });
      }
    };

    const addOption = (option: any, index) => {
      console.log(schema, "schema");
      const _schema = cloneDeep(schema);
      const _form = cloneDeep(formData);
      const name = _schema[index].mainBlock.name;

      let options = cloneDeep(option);
      delete options.blocks.mainpage;
      options = options.blocks;
      const key = Object.keys(options)[0];
      options = options[key];

      const length = _schema.filter((el: any) => {
        return el.mainBlock.name == name;
      }).length;

      let findIndex;

      console.log(length - 1 > 0, _form[name], "sss");
      if (length - 1 > 0 && _form[name]) {
        findIndex = _schema.findIndex((el: any) => {
          return el.mainBlock.name == name && el.num == length - 1;
        });
      } else {
        findIndex = _schema.findIndex((el: any) => {
          return el.mainBlock.name == name && el.num === undefined;
        });
      }

      const newRecord = cloneDeep(_schema[findIndex]);

      const subItem = newRecord.mainBlock.blocks.find((el: any) => {
        return el.subBlockName == key;
      });

      const pgSubItem = option.pg[0].pgSchema[0].mainBlock.blocks.find(
        (el: any) => {
          return el.subBlockName == key;
        }
      );

      let k = schema[index].mainBlock.blocks.find((el: any) => {
        return el.subBlockName == key;
      });

      k = cloneDeep(k);

      k.multi = false;
      delete k.subBlocks;

      subItem.subBlocks = [];
      for (let i = 0; i < option.blocks[key][0]["subBlocks"][key].length; i++) {
        subItem.subBlocks.push(k);
      }

      if (length - 1 > 0 || _form[name]) {
        newRecord.num = length;
        _schema.splice(findIndex + 1, 0, newRecord);
      } else {
        _schema[findIndex] = newRecord;
      }

      if (_form[name]) {
        _form[name].push(options[0]);
      } else {
        _form[name] = options;
      }

      setFormData(_form);

      setSchema(_schema);
    };

    return (
      <>
        {schema.map((el: any, index2: number) => {
          let multi = schema.filter((elx: any) => {
            return (
              elx.mainBlock.name == el.mainBlock.name &&
              elx?.num &&
              el.mainBlock.multiple === true
            );
          });

          return (
            <div
              key={`z${index2}`}
              className={`${!el?.num ? " p-5 border-b" : ""}`}
            >
              <div key={`${el.mainBlock?.displayName}`}>
                {!el?.num && (
                  <div className="font-bold flex items-center py-2 justify-between w-full  ">
                    {!el?.num && (
                      <div className="font-small-caps lowercase">
                        <h4 className="text-base py-[0.2em] uppercase">
                          {el.mainBlock?.displayName}
                        </h4>
                      </div>
                    )}

                    {!el?.num &&
                      (el?.mainBlock?.multiple ||
                        el?.mainBlock.flodAble == true) && (
                        <div className=" flex ">
                          {el?.mainBlock.copyFrom && (
                            <span>
                              <Combobox
                                endpoint={el?.mainBlock?.copyFrom}
                                addOption={(option) =>
                                  addOption(option, index2)
                                }
                              />
                            </span>
                          )}

                          {el?.mainBlock?.multiple && (
                            <Button
                              className="ml-2 "
                              variant={"outline"}
                              onClick={() => {
                                addField(index2);
                              }}
                            >
                              <Plus size={"1em"} />
                            </Button>
                          )}
                          {el?.mainBlock.flodAble == true && (
                            <Button
                              className="ml-2"
                              variant={"outline"}
                              onClick={() => {
                                setMyFlod(el);
                              }}
                            >
                              <>
                                {el?.mainBlock.flodAble == true &&
                                  el?.mainBlock?.unFlod && (
                                    <UnfoldVertical size={"1em"} />
                                  )}
                                {el?.mainBlock.flodAble == true &&
                                  !el?.mainBlock?.unFlod && (
                                    <FoldVertical size={"1em"} />
                                  )}
                              </>
                            </Button>
                          )}
                        </div>
                      )}
                  </div>
                )}

                {!el?.num && (
                  <div
                    className={`${
                      el?.mainBlock?.unFlod || el?.mainBlock?.flodAble != true
                        ? "h-full overflow-auto"
                        : "h-0 overflow-hidden"
                    } transition-all duration-500 ease-in-out`}
                  >
                    <div
                      key={`f${index2}`}
                      className="w-full relative max-w-full"
                    >
                      {el.mainBlock.blocks.map((elx: any, index3: number) => {
                        return (
                          <div
                            className="grid grid-cols-[1fr,auto]  items-start gap-2 px-2  border-top py-2"
                            key={`${index2}-${index3}-${el.num}`}
                          >
                            <div>
                              <div className="flex gap-5">
                                <div className="w-full">
                                  {renderBlock(
                                    elx.block,
                                    index3,
                                    el.mainBlock.name,
                                    el.num || 0
                                  )}
                                </div>
                                <div className="pt-[3.5em]">
                                  {elx?.multi && (
                                    <Button
                                      variant={"outline"}
                                      onClick={() =>
                                        addSubRow(
                                          index2,
                                          index3,
                                          elx.subBlockName || "",
                                          el.num || 0
                                        )
                                      }
                                    >
                                      <Plus size={"12px"} />
                                    </Button>
                                  )}
                                </div>
                              </div>

                              {/* subitemsy */}
                              {el.mainBlock.blocks[index3]?.subBlocks &&
                                el.mainBlock.blocks[index3].subBlocks.map(
                                  (elh: any, index5: number) => {
                                    return (
                                      <div className="flex gap-5">
                                        {" "}
                                        <div className="w-full">
                                          {renderBlock(
                                            elh.block,
                                            index3,
                                            el.mainBlock.name,
                                            el.num || 0,
                                            "subBlock",
                                            true,
                                            index5,
                                            elx.subBlockName || "test",
                                            elx.num || 0
                                          )}
                                        </div>
                                        <div className="pt-[3.5em] w-[40px]">
                                          <RowOperations
                                            showUp={
                                              index5 || 0 > 0 ? true : false
                                            }
                                            index={index5}
                                            elNum={index5}
                                            showDown={
                                              index5 <
                                              el.mainBlock.blocks[index3]
                                                .subBlocks.length -
                                                1
                                            }
                                            onDelete={() => {
                                              deleteSubField(
                                                index5,
                                                index2,
                                                index3,
                                                el.num
                                              );
                                            }}
                                            onUp={() =>
                                              moveUpSubField(
                                                index5,
                                                index2,
                                                index3,
                                                el.num
                                              )
                                            }
                                            onDown={() => {
                                              moveDownSubField(
                                                index5,
                                                index2,
                                                index3,
                                                el.num
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {multi &&
                      multi.map((elw, index10) => {
                        return (
                          <div className="mt-5 ">
                            <div className="py-2 flex justify-between border-b">
                              <h4 className="text-base py-[0.2em] uppercase font-bold">
                                {elw.mainBlock.displayName} {index10 + 1}
                              </h4>
                              <div className="w-[40px]">
                                <RowOperations
                                  showUp={elw?.num || 0 > 0 ? true : false}
                                  index={index2}
                                  elNum={elw.num}
                                  showDown={
                                    findMaxNum(schema[index2].mainBlock.name) >
                                      elw?.num || false
                                  }
                                  onDelete={() => {
                                    deleteField(index2 + index10 + 1, elw?.num);
                                  }}
                                  onUp={() => moveUp(index2, elw?.num)}
                                  onDown={() => {
                                    moveDown(index2, elw?.num);
                                  }}
                                />
                              </div>
                            </div>
                            {elw.mainBlock.blocks.map(
                              (elh: any, index3: number) => {
                                return (
                                  <>
                                    <div
                                      className="grid grid-cols-[1fr,auto]  items-start gap-2 px-2   py-2 border-t-0"
                                      key={`${index2}-${index3}-${el.num}`}
                                    >
                                      <div>
                                        <div className="flex gap-5">
                                          <div className="w-full">
                                            {renderBlock(
                                              elh.block,
                                              index3,
                                              elw.mainBlock.name,
                                              elw.num || 0
                                            )}
                                          </div>
                                          <div className="mt-[3.5em]">
                                            {elh?.multi && (
                                              <Button
                                                variant={"outline"}
                                                onClick={() =>
                                                  addSubRow(
                                                    index2,
                                                    index3,
                                                    elh.subBlockName || "",
                                                    elw.num || 0
                                                  )
                                                }
                                              >
                                                <Plus size={"12px"} />
                                              </Button>
                                            )}
                                          </div>
                                        </div>

                                        {/* subitemsy */}
                                        {elw.mainBlock.blocks[index3]
                                          ?.subBlocks &&
                                          elw.mainBlock.blocks[
                                            index3
                                          ].subBlocks.map(
                                            (elh: any, index5: number) => {
                                              return (
                                                <div className="flex gap-5">
                                                  <div className="w-full">
                                                    {renderBlock(
                                                      elh.block,
                                                      index3,
                                                      elw.mainBlock.name,
                                                      elw.num || 0,
                                                      "subBlock",
                                                      true,
                                                      index5,
                                                      elh.subBlockName ||
                                                        "test",
                                                      elw.num || 0
                                                    )}
                                                  </div>
                                                  <div className="pt-[3.5em] w-[40px]">
                                                    <RowOperations
                                                      showUp={
                                                        index5 || 0 > 0
                                                          ? true
                                                          : false
                                                      }
                                                      index={index5}
                                                      elNum={index5}
                                                      showDown={
                                                        index5 <
                                                        elw.mainBlock.blocks[
                                                          index3
                                                        ].subBlocks.length -
                                                          1
                                                      }
                                                      onDelete={() => {
                                                        deleteSubField(
                                                          index5,
                                                          index2 + index10 + 1,
                                                          index3,
                                                          elw.num
                                                        );
                                                      }}
                                                      onUp={() =>
                                                        moveUpSubField(
                                                          index5,
                                                          index2 + index10 + 1,
                                                          index3,
                                                          elw.num
                                                        )
                                                      }
                                                      onDown={() => {
                                                        moveDownSubField(
                                                          index5,
                                                          index2 + index10 + 1,
                                                          index3,
                                                          elw.num
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </div>
                        );
                      })}
                    {el?.mainBlock?.multiple && (
                      <div className="flex justify-end pt-8 pb-8">
                        <div>
                          <strong className="uppercase text-sm">
                            Nowa pozycja
                          </strong>
                          <Button
                            className="ml-2 "
                            variant={"outline"}
                            onClick={() => {
                              addField(index2);
                            }}
                          >
                            <Plus size={"1em"} />
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })}

        {props?.inDialog == false && (
          <div className="flex justify-end pt-8 pb-8">
            {props?.closeButton == true && (
              <Button
                className="mr-8"
                onClick={() => {
                  props?.onClose && props.onClose();
                }}
              >
                zamknij
              </Button>
            )}
            {props.resetButton && (
              <Button
                className="mr-8"
                onClick={() => {
                  resetForm && resetForm();
                }}
              >
                resetuj
              </Button>
            )}
            {props.saveButton && (
              <Button
                className="mr-8"
                onClick={() => {
                  save();
                }}
              >
                zapisz
              </Button>
            )}
          </div>
        )}
      </>
    );
  }
);

EnhancedFrom.defaultProps = {
  validationType: "ON_SUBMIT",
  saveButton: true,
};
export default EnhancedFrom;
