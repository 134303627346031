import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { Cookies } from 'react-cookie'

export const cuponApi = createApi({
    reducerPath: 'cuponApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {

            const token = new Cookies().get('token');

            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        }
    }),
    tagTypes: ['cupon'],
    endpoints: (builder) => ({
        cupon: builder.query({
            query: (arg) => {

                if (arg == undefined) {
                    return { url: '/cupon/', method: 'GET' }
                } else {
                    return { url: `/cupon/${arg}`, method: 'GET' }
                }
            },

        }),
        addCupon: builder.mutation({
            query: (page) => ({
                url: '/cupon/',
                method: 'POST',
                body: page
            }),
            invalidatesTags: ['cupon']
        }),
        updateCupon: builder.mutation({
            query: ({ id, rest }) => ({
                url: `/cupon/${id}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['cupon']
        }),
        deleteCupon: builder.mutation({
            query: (id) => ({
                url: `/cupon/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['cupon']
        })
    })
});

export const { useCuponQuery, useAddCuponMutation, useUpdateCuponMutation, useDeleteCuponMutation } = cuponApi;
