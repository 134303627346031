import { Button } from "../ui/button";
import { CompontentsType } from "./types";
import { InputWithLabel } from "../ui/inputWithLabel";
import { CheckboxWithLabel } from "../ui/checkboxWithLabel";
import { Editor } from "../ui/editor";
import { Unk } from "../ui/unk";

import { DatePicker } from "../ui/datePicker";
import { TextareaWithLabel } from "../ui/textarea";
import { ImagePicker } from "../ui/imagePicker";
import { GalleryPicker } from "../ui/galleryPicker";
import { UiFragment } from "../ui/UiFragment";
import { DynamicLabel } from "../ui/dynamicLabel";
import { UiSelect } from "../ui/UiSelect";
import { UiSelectEndpoint } from "../ui/UiSelectEndpoint";
import { UiSelectEndpointMulti } from "../ui/UiSelectEndpointMulit";
import { UiButton } from "../ui/UiButton";
import { UiSelectMulti } from "../ui/UiSelectMulit";
import { PasswordWithLabel } from "../ui/passwordWithLabel";
import { UploadWithLabel } from "../ui/uploadWithLabel";
import { YTLink } from "../ui/ytLink";
import { UiCheckBoxTree } from "../ui/UiSelectEndpointTree";
import { InputSlug } from "../ui/inputSlug";

export const Components: CompontentsType = {
  text: { component: InputWithLabel, action: "onChange" },
  inputSlug: { component: InputSlug, action: "onChange" },
  checkbox: {
    component: CheckboxWithLabel,
    action: "onCheckedChange",
    checkbox: true,
  },
  ytLink: { component: YTLink, action: "onChangeRaw" },
  unk: { component: Unk, action: "onChange" },
  fileupload: { component: UploadWithLabel, action: "onChangeRaw" }, //#todo
  password: { component: PasswordWithLabel, action: "onChange" },
  slideshow: { component: Unk, action: "onChange" }, //#todo
  slider: { component: Unk, action: "onChange" }, //#todo
  image: { component: ImagePicker, action: "onChangeRaw" },
  gallery: { component: GalleryPicker, action: "onChangeRaw" },
  editor: { component: Editor, action: "onChangeRaw" },
  date: { component: DatePicker, action: "onChangeRaw" },
  select: { component: UiSelect, action: "onChangeRaw" },
  multiselect: { component: UiSelectMulti, action: "onChangeRaw" },
  textarea: { component: TextareaWithLabel, action: "onChange" },
  fragment: { component: UiFragment, action: "onChangeRaw" },
  selectEndpoint: { component: UiSelectEndpoint, action: "onChangeRaw" },
  treeCheckbox: { component: UiCheckBoxTree, action: "onChangeRaw" },
  multiselectEndpoint: {
    component: UiSelectEndpointMulti,
    action: "onChangeRaw",
  },
  dynamicLabel: { component: DynamicLabel, action: "onChange" },
  button: { component: UiButton, action: "onClick" },
};
